<template>
      <ion-header>
        <ion-toolbar>
          <ion-title>개인정보변경</ion-title>
          <ion-button slot="end" @click="closeModal" color="medium" fill="clear">닫기</ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content :fullscreen="true">
        <ion-item>
          <ion-label position="floating">업체담당자명</ion-label>
          <ion-input type="text" v-model="form.priv.priv" maxlength="20" placeholder="업체담당자명을 입력해주세요"></ion-input>
        </ion-item>
        <ion-footer> 
          <div class='center-button'>
          <ion-button class="record-btn" color="medium" @click="submit">변경</ion-button>
        </div>
        </ion-footer>    
      </ion-content>
</template>

<script lang="ts">
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, modalController,IonFooter } from "@ionic/vue";
import { defineComponent, inject, reactive } from "vue";
import { Emitter, EventType } from 'mitt';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, IonFooter },
  props: {
    title: {
      type: String
    },
    priv: {
      type: String
    }
  },

  setup(props) {
    const {isConnected,updateUserInfo, fetchUserInfos } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');

    const form = reactive({
      title: props.title,
      priv: {
        priv: props.priv
      }
    });
    function closeModal() {
      if (emitter) {
        emitter.emit('fetchUserInfos');
      }
      modalController.dismiss();
    }
    function submit() {
        updateUserInfo(form.title, form.priv)
        emitter.emit('fetchUserInfos');
    }
    return { form, closeModal, updateUserInfo, submit ,isConnected,fetchUserInfos}
  }
});

</script>

<style>
.center-button {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  height: 20%;
}

.record-btn {
  height: 40px;
  width: 120px;
}
</style>