<template>
  <ion-page>
    <app-header back="home" title="개인정보변경" />
    <ion-content :fullscreen="true">
      <div id="container" class="text-align-center">
        <template v-if="isConnected">
          <template v-if="userInfo">
            <ion-item>
              <ion-label>회사명</ion-label>
              <ion-text color ="medium"> {{ userInfo.userName }}</ion-text>
            </ion-item>
            <ion-item button  @click="updatePriv">
              <ion-label>업체담당자명</ion-label>
              <ion-text> {{ userInfo.priv}}</ion-text>
            </ion-item>
            <ion-item button @click ="updateTlno">
              <ion-label>연락처</ion-label>
              <ion-text> {{ userInfo.tlno}}</ion-text>
            </ion-item>
            <ion-item>
              <ion-label>사업자번호</ion-label>
              <ion-text color ="medium"> {{ userInfo.userSano }}</ion-text>
            </ion-item>
            <ion-item button  @click="updateEmailAddr">
              <ion-label>이메일</ion-label>
              <ion-text>{{ userInfo.userMail }}</ion-text>
            </ion-item>
            <!-- <ion-item button="true" detail="false" @click="updateReinstatementDate">
              <ion-label>복직예정일</ion-label>
              <ion-text>{{ userInfo.gtshtday }}</ion-text>
            </ion-item> -->
            <ion-item button @click="updatePassword">
              <ion-label>비밀번호</ion-label>
            </ion-item>
            <!-- <ion-item button="true" detail="false" router-link="/plic">
              <ion-label>자격/면허 관리</ion-label>
              <ion-icon slot="end" :icon="chevronForwardOutline" />
            </ion-item>
            <ion-item button="true" detail="false" router-link="/edu">
              <ion-label>교육 관리</ion-label>
              <ion-icon slot="end" :icon="chevronForwardOutline" />
            </ion-item> -->
          </template>
          <template v-else>
            <ion-item >
              <ion-label>사용자(회사)명</ion-label>
              <ion-skeleton-text animated />
            </ion-item>
            <ion-item >
              <ion-label>사업자번호</ion-label>
              <ion-skeleton-text animated />
            </ion-item>
            <ion-item >
              <ion-label>이메일</ion-label>
              <ion-skeleton-text animated />
            </ion-item>
            <!-- <ion-item detail="false">
              <ion-label>복직예정일</ion-label>
              <ion-skeleton-text animated />
            </ion-item> -->
            <ion-item >
              <ion-label>비밀번호</ion-label>
              <ion-skeleton-text animated />
            </ion-item>
            <!-- <ion-item detail="false" button="true">
              <ion-label>자격/면허 관리</ion-label>
              <ion-icon slot="end" :icon="chevronForwardOutline" />
            </ion-item>
            <ion-item detail="false" button="true">
              <ion-label>교육 관리</ion-label>
              <ion-icon slot="end" :icon="chevronForwardOutline" />
            </ion-item> -->
          </template>
        </template>
        <template v-else>
          <network-error v-on:@onReConnect="onReConnect" />
        </template>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang ='ts'>
import {
  IonContent,
  // IonItem,
  IonText,
  IonLabel,
  IonPage,
  IonSkeletonText,
  onIonViewWillEnter,
  IonItem,
  modalController
} from '@ionic/vue';
import { defineComponent ,reactive, inject, computed} from 'vue';
import { useStore } from 'vuex';
// import { chevronForwardOutline } from 'ionicons/icons';
import { users } from '@/api/services/user';
import { Emitter, EventType } from 'mitt';
import Modal1  from '@/views/modal/UserModal1.vue'
import Modal2  from '@/views/modal/UserModal2.vue'
import Modal3  from '@/views/modal/UserModal1_sub.vue'
import Modal4  from '@/views/modal/UserModal1_sub2.vue'
export default defineComponent({
  components: {
    IonContent,
    // IonIcon,
    // IonItem,
    IonText,
    IonLabel,
    IonPage,
    IonSkeletonText,
    IonItem,
  },
  setup() {
    const { isConnected, userInfo, fetchUserInfos, saveLog } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');
      const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '개인정보변경');
    });
    
const form  = reactive({  
  title: '',
  priv:'',
  email:'',
  tlno:''

});

const openModal = async () => {
      const modal = await modalController.create({
        component: Modal1,
        cssClass: 'my-modal-class',
        componentProps:{
          title: form.title,
          priv: form.priv
        }
        //Modal is name of the component to render inside ionic modal
      });
      modal.onWillDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
      
    };
    const openModalTlno = async () => {
      const modal = await modalController.create({
        component: Modal4,
        cssClass: 'my-modal-class',
        componentProps:{
          title: form.title,
          tlno: form.tlno
        }
        //Modal is name of the component to render inside ionic modal
      });
      modal.onWillDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
      
    };    
    const openModalEmail = async () => {
      const modal = await modalController.create({
        component: Modal3,
        cssClass: 'my-modal-class',
        componentProps:{
          title: form.title,
          email: form.email
        }
        //Modal is name of the component to render inside ionic modal
      });
      modal.onWillDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
      
    };
    const openModalPassword = async () => {
      const modal = await modalController.create({
        component: Modal2,
        cssClass: 'my-modal-class2',
        componentProps:{
          title: form.title
        } 
        //Modal is name of the component to render inside ionic modal
      });
      modal.onDidDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
    }; 
    
      
    onIonViewWillEnter(async () => {
      await fetchUserInfos();
    });

    async function onReConnect() {
      await fetchUserInfos();
    }
    if (emitter) {
      emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    
    function updatePriv() {
      form.title = 'priv'
      form.priv = userInfo.value.priv
      openModal();
    }
    function updateTlno() {
      form.title = 'tlno'
      form.tlno = userInfo.value.tlno
      openModalTlno();
    }
    function updatePassword() {
      form.title = 'pwd'
      openModalPassword();
    }

    function updateEmailAddr() {
      form.title = 'email'
      form.email = userInfo.value.userMail
      openModalEmail();
    }
    

    return {
      // chevronForwardOutline,
      isConnected,
      userInfo,
      updatePriv,
      updateEmailAddr,
      updatePassword,
      updateTlno,
      onReConnect,
      openModal,
      openModalPassword,
      openModalEmail
    };
  }
});
</script>

<style scoped>
#container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
ion-item {
  --padding-start: 0px;
}
ion-item ion-label {
  padding-left: 10px;
  min-width: 4em;
}
ion-item ion-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
ion-button {
  width: 100%;
  margin: 1.5em 0;
  height: 3em;
}
ion-skeleton-text {
  width: 30%;
}
.my-modal-class .modal-wrapper {
   height: 20%;
   top: 20%;
   position: absolute; 
   display: block;  
}
</style>