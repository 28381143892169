
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, modalController,IonFooter } from "@ionic/vue";
import { defineComponent, inject, reactive } from "vue";
import { Emitter, EventType } from 'mitt';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, IonFooter },
  props: {
    title: {
      type: String
    },
    tlno: {
      type: String
    }
  },

  setup(props) {
    const {isConnected,updateUserInfo, fetchUserInfos } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');

    const form = reactive({
      title: props.title,
      tlno: {
        tlno: props.tlno
      }

    });
    function closeModal() {
      if (emitter) {
        emitter.emit('fetchUserInfos');
      }
      modalController.dismiss();
    }
    function submit() {
        updateUserInfo(form.title, form.tlno)
        emitter.emit('fetchUserInfos');
     
    }
    return { form, closeModal, updateUserInfo, submit ,isConnected,fetchUserInfos}
  }
});

