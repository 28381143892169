
import {
  IonContent,
  // IonItem,
  IonText,
  IonLabel,
  IonPage,
  IonSkeletonText,
  onIonViewWillEnter,
  IonItem,
  modalController
} from '@ionic/vue';
import { defineComponent ,reactive, inject, computed} from 'vue';
import { useStore } from 'vuex';
// import { chevronForwardOutline } from 'ionicons/icons';
import { users } from '@/api/services/user';
import { Emitter, EventType } from 'mitt';
import Modal1  from '@/views/modal/UserModal1.vue'
import Modal2  from '@/views/modal/UserModal2.vue'
import Modal3  from '@/views/modal/UserModal1_sub.vue'
import Modal4  from '@/views/modal/UserModal1_sub2.vue'
export default defineComponent({
  components: {
    IonContent,
    // IonIcon,
    // IonItem,
    IonText,
    IonLabel,
    IonPage,
    IonSkeletonText,
    IonItem,
  },
  setup() {
    const { isConnected, userInfo, fetchUserInfos, saveLog } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');
      const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);

    onIonViewWillEnter(async () => {
      saveLog(auth.value.id, '개인정보변경');
    });
    
const form  = reactive({  
  title: '',
  priv:'',
  email:'',
  tlno:''

});

const openModal = async () => {
      const modal = await modalController.create({
        component: Modal1,
        cssClass: 'my-modal-class',
        componentProps:{
          title: form.title,
          priv: form.priv
        }
        //Modal is name of the component to render inside ionic modal
      });
      modal.onWillDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
      
    };
    const openModalTlno = async () => {
      const modal = await modalController.create({
        component: Modal4,
        cssClass: 'my-modal-class',
        componentProps:{
          title: form.title,
          tlno: form.tlno
        }
        //Modal is name of the component to render inside ionic modal
      });
      modal.onWillDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
      
    };    
    const openModalEmail = async () => {
      const modal = await modalController.create({
        component: Modal3,
        cssClass: 'my-modal-class',
        componentProps:{
          title: form.title,
          email: form.email
        }
        //Modal is name of the component to render inside ionic modal
      });
      modal.onWillDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
      
    };
    const openModalPassword = async () => {
      const modal = await modalController.create({
        component: Modal2,
        cssClass: 'my-modal-class2',
        componentProps:{
          title: form.title
        } 
        //Modal is name of the component to render inside ionic modal
      });
      modal.onDidDismiss().then((() => {
        if (emitter) {
        emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    }));
      return modal.present();
    }; 
    
      
    onIonViewWillEnter(async () => {
      await fetchUserInfos();
    });

    async function onReConnect() {
      await fetchUserInfos();
    }
    if (emitter) {
      emitter.on('fetchUserInfos', () => {
        fetchUserInfos();     
      });
    }
    
    function updatePriv() {
      form.title = 'priv'
      form.priv = userInfo.value.priv
      openModal();
    }
    function updateTlno() {
      form.title = 'tlno'
      form.tlno = userInfo.value.tlno
      openModalTlno();
    }
    function updatePassword() {
      form.title = 'pwd'
      openModalPassword();
    }

    function updateEmailAddr() {
      form.title = 'email'
      form.email = userInfo.value.userMail
      openModalEmail();
    }
    

    return {
      // chevronForwardOutline,
      isConnected,
      userInfo,
      updatePriv,
      updateEmailAddr,
      updatePassword,
      updateTlno,
      onReConnect,
      openModal,
      openModalPassword,
      openModalEmail
    };
  }
});
