<template>
    <ion-header>
      <ion-toolbar>
        <ion-title>비밀번호 변경</ion-title>
        <ion-button slot="end" @click="closeModal" color="medium" fill="clear">닫기</ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item>
        <ion-label position="floating">현재 비밀번호</ion-label>
        <ion-input type="password" v-model='form.pwd.password' maxlength="20"
          placeholder="이전 비밀번호 입력"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">새 비밀번호</ion-label>
        <ion-input type="password" v-model='form.pwd.newPassword' maxlength="20" placeholder="새로운 비밀번호 입력"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="floating">새 비밀번호 확인</ion-label>
        <ion-input type="password" v-model='form.pwd.passwordConfirm' maxlength="20"
          placeholder="새로운 비밀번호 확인"></ion-input>
      </ion-item>
      <ion-footer>
        <div class='center-button'>
          <ion-button class="record-btn" color="medium" @click="submit">변경</ion-button>
        </div>
      </ion-footer>
    </ion-content>
</template>

<script lang="ts">
import { users } from '@/api/services/user';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton, modalController, IonFooter } from "@ionic/vue";
import { defineComponent, inject, reactive } from "vue";
import { Emitter, EventType } from 'mitt';
export default defineComponent({

  name: "Modal",
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonLabel, IonInput, IonButton,IonFooter},
  props: {
    title: {
      type: String
    },
    name: {
      type: String
    },
    email: {
      type: String
    }
  },

  setup(props) {
    const { updateUserInfo } = users();
    const emitter = inject<Emitter<Record<EventType, unknown>>>('emitter');

    const form = reactive({
      title: props.title,
      pwd: {
        password: '',
        newPassword: '',
        passwordConfirm: ''
      }

    });

    function closeModal() {
      if (emitter) {
        emitter.emit('fetchUserInfos');
      }
      modalController.dismiss();
    }
    function submit() {
      updateUserInfo(form.title, form.pwd)
      emitter.emit('fetchUserInfos');
    }


    return { form, closeModal, updateUserInfo, submit }
  }
});

</script>

<style>
.center-button {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  height: 20%;
}

.record-btn {
  height: 40px;
  width: 120px;
}
</style>